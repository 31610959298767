import React, {useEffect} from 'react';
import "./Contact.scss";

function Contact(props) {
    useEffect(() => {
        (function loadHubSpot() {
            window.hbspt.forms.create({
                region: "na1",
                portalId: "5152848",
                formId: "5b625ff4-99d5-4cb2-b2d6-c49d7cb1e44a",
                target: '.contact-wrapper'
            });
        })();
    }, []);

    return (
        <>
            <p className={'contact-us-form'}>Contact us:</p>
            <div className="contact-wrapper animate__animated animate__zoomInDown"></div>
        </>
    );
}

export default Contact;
